/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo } from 'react';

import { apiRootUrl, apiBasicAuth } from '../../config';
import Table from '../Table';

const Standings = () => {
  const [players, setPlayers] = useState([]);
  const collator = new Intl.Collator('is-Is');

  const COLUMNS = useMemo(() => [
    {
      Header: 'Nafn',
      accessor: 'name',

      sortDescFirst: 'true',
      sortType: (rowA, rowB, id) => {
        if (collator.compare(rowA.original[id], rowB.original[id]) > 0) {
          return -1;
        }
        if (collator.compare(rowB.original[id], rowA.original[id]) > 0)
          return 1;
        return 0;
      },

      Cell: (row) => (
        <div style={{ textAlign: 'left' }}>
          {row.value.split(' ').slice(0, -1).join(' ')}
        </div>
      ),
    },
    {
      Header: 'Leikir',
      accessor: 'total_games',
      sortDescFirst: 'true',
    },
    {
      Header: 'Mörk',
      accessor: 'total_goals',
      sortDescFirst: 'true',
    },
    {
      Header: 'Stig',
      accessor: 'total_points',
    },
  ]);

  useEffect(() => {
    async function fetchPlayers() {
      const res = await fetch(`${apiRootUrl}/players/seasons/2025H1/table`, {
        method: 'GET',
        headers: {
          Authorization: apiBasicAuth,
        },
      });
      const data = await res.json();
      setPlayers(data);
    }
    fetchPlayers();
  }, []);

  return <Table tableData={players} columns={COLUMNS} />;
};

export default Standings;
